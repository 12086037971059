import { template as template_68f7654ea85b435c8978ac1688a3be3f } from "@ember/template-compiler";
const FKControlMenuContainer = template_68f7654ea85b435c8978ac1688a3be3f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_92a5e4e9b19046d19bcbf4c7d8b6f707 } from "@ember/template-compiler";
const WelcomeHeader = template_92a5e4e9b19046d19bcbf4c7d8b6f707(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
